import React from 'react'
import Carousel from '../Carousel/Carousel'
import Categories from '../Categories/Categories'
import IconDeliveryCat from '../icons/IconDeliveryCat'
import IconXmasCat from '../icons/IconXmasCat'
import Letters from '../Letters'
import OpenTimes from '../OpenTimes/OpenTimes'
import {Parallax, ParallaxLayer} from "@react-spring/parallax";

import dahlia from '../images/dahlia.png'
import hibiscus from '../images/hibiscus.png'
import roses from '../images/roses.svg'

import './Parallax.scss'
import IconBouquetCat from "../icons/IconBouquetCat";
import IconBirthdayCat from "../icons/IconBirthdayCat";


class ParallaxPage extends React.PureComponent {
    render() {
        return (
            <Parallax
                className="main-parallax"
                style={{
                    left: 0,
                    scrollBehavior: 'smooth',
                }}
                ref={(ref) => {
                    this.parallax = ref
                }}
                pages={2.3}
            >
                <ParallaxLayer
                    offset={0.125}
                    speed={-0.25}
                    style={{
                        scrollBehavior: 'smooth',
                    }}
                >
                    <div
                        style={{
                            margin: '0 1rem 3rem',
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <IconBirthdayCat height="120"/>
                    </div>
                    <OpenTimes/>
                </ParallaxLayer>

                <ParallaxLayer
                    style={{
                        scrollBehavior: 'smooth',
                    }}
                    offset={0.6}
                    speed={0.3}
                >
                    <div
                        style={{
                            margin: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <IconBouquetCat height="150"/>
                    </div>
                    <Carousel/>
                </ParallaxLayer>

                <ParallaxLayer
                    offset={1.25}
                    speed={0.4}
                    style={{
                        scrollBehavior: 'smooth',
                    }}
                >
                    <div
                        style={{
                            marginTop: '10%',
                        }}
                    >
                        <Categories/>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    style={{
                        scrollBehavior: 'smooth',
                    }}
                    offset={1.85}
                    speed={-0.1}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <IconXmasCat width="100"/>
                        <Letters maxWidth="85%" width="7%" fill="rgba(101,55,148,1)"/>
                    </div>
                </ParallaxLayer>

                <ParallaxLayer
                    style={{
                        scrollBehavior: 'smooth',
                        opacity: 0.5,
                    }}
                    offset={1.85}
                    speed={0.5}
                >
                    <img
                        alt="hibiscus"
                        src={hibiscus}
                        style={{
                            display: 'block',
                            width: '10%',
                            marginLeft: '85%',
                        }}
                    />
                    <img
                        alt="dahlia"
                        src={dahlia}
                        style={{
                            display: 'block',
                            width: '10%',
                            marginLeft: '15%',
                        }}
                    />
                    <img
                        alt="roses"
                        src={roses}
                        style={{
                            display: 'block',
                            width: '15%',
                            marginLeft: '45%',
                        }}
                    />
                </ParallaxLayer>

                <ParallaxLayer
                    style={{
                        scrollBehavior: 'smooth',
                    }}
                    offset={2.15}
                    speed={-0}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}
                    >
                        <IconDeliveryCat width="100"/>
                    </div>
                </ParallaxLayer>

            </Parallax>
        )
    }
}

export default ParallaxPage
