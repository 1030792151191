import React from 'react'

const L = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width={props.width}
      height={props.height}
    >
      <title>L</title>
      <path
        fill={props.fill}
        d="M370.14,360.38a76.86,76.86,0,0,0-3.63-16.66,75.5,75.5,0,0,0-7-15.85,12,12,0,0,0-10.1-5.78H349c-19.72-.25-39.44-.43-59.16-.27-15.84,0-31.69.39-47.53.66-.47-22.82-.91-45.64-1.59-68.47-.84-26.85-2.36-53.69-3.69-80.54l-1-20.14-.79-20.14c-.21-6.74-.52-13.94-2.41-21S227.22,98.1,221,93.34c-6-4.66-13.3-7-20.21-8a133.64,133.64,0,0,0-20.69-1.55,193.36,193.36,0,0,0-20.59,1.08l-20.24,2.28a4.74,4.74,0,0,0-4.2,4.49l0,.58c-1.33,28.67-3.5,57.34-4.64,86s-1.25,57.34-.26,86,3.46,57.34,5.53,86l.8,10.84a70.21,70.21,0,0,0,1.85,11.19,48.5,48.5,0,0,0,10.2,20.07A45,45,0,0,0,166.72,405a60.63,60.63,0,0,0,21.56,3.6q29,.09,58,1.71c19.34,1.11,38.68,2.6,58,4.15l14.51,1.1,7.25.52a52.66,52.66,0,0,0,8.63-.08,39.43,39.43,0,0,0,17.84-6.12,35.45,35.45,0,0,0,3.93-2.95,32.35,32.35,0,0,0,3.74-3.78,37.12,37.12,0,0,0,5.34-8.66A59.28,59.28,0,0,0,370,377.18c.31-2.81.43-5.49.5-8.27A77.39,77.39,0,0,0,370.14,360.38Zm-23,15a38.89,38.89,0,0,1-1.68,10.95,16.63,16.63,0,0,1-1.77,3.93,10.45,10.45,0,0,1-1.18,1.53,22.56,22.56,0,0,1-1.68,1.64,21.35,21.35,0,0,1-8.9,4.41,33.76,33.76,0,0,1-5.82.9l-7.25.52-14.51,1.1c-19.34,1.54-38.68,3-58,4.15s-38.68,1.66-58,1.71c-7,0-14.14-1-20.54-3.78a38.34,38.34,0,0,1-16.08-12.58,40.26,40.26,0,0,1-7.28-18.76,56.66,56.66,0,0,1-.37-10.17c.16-3.41.49-7.12.74-10.67,2.07-28.67,4.59-57.34,5.53-86s.84-57.34-.26-86c-1.08-27.29-3.09-54.58-4.43-81.86,5-1,10.1-2.06,15.12-3a143.12,143.12,0,0,1,19.54-2,104,104,0,0,1,19.44,1.05c6.3.95,12.22,3,16.8,6.69s7.27,9.1,8.5,15a75,75,0,0,1,1.08,19.05l-.79,20.14-1,20.14c-1.32,26.85-2.85,53.69-3.69,80.54s-1.26,53.69-1.85,80.54v.1a11.63,11.63,0,0,0,11.47,11.79h.42c19.72.27,39.44.79,59.16.84,17.27.13,34.53,0,51.8-.19.66,1.47,1.29,3,1.88,4.61a52.93,52.93,0,0,1,3,11.51,53.74,53.74,0,0,1,.59,5.93C347.19,371.19,347.22,373.36,347.11,375.36Z"
      />
    </svg>
  )
}

export default L
