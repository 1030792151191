import React from 'react'

const O = (props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      width={props.width}
      height={props.height}
    >
      <title>O</title>
      <path
        fill={props.fill}
        d="M417.26,249.11c-1-22.46-4.83-44.89-12.59-66s-19.52-40.63-35.06-57a158.44,158.44,0,0,0-25.64-22,169.37,169.37,0,0,0-29.76-16.57A180.3,180.3,0,0,0,281.6,76.93a194.31,194.31,0,0,0-34.09-4.3c-22.86-.84-46.34,2.41-68.13,10.73a140.89,140.89,0,0,0-56.86,39.5,145.45,145.45,0,0,0-19.12,28.56,162.77,162.77,0,0,0-6.94,15.46c-2.08,5.22-3.95,10.5-5.51,15.88-6.21,21.54-8.37,43.66-8.44,65.65s2.08,44.14,8.24,65.71c1.54,5.39,3.39,10.69,5.44,15.92A164,164,0,0,0,103,345.56,148.6,148.6,0,0,0,122,374.32a143.56,143.56,0,0,0,56.62,40.42c21.8,8.69,45.32,12.56,68.45,12.74s46.69-3.32,68.73-11.68A164.87,164.87,0,0,0,347.42,400a153.18,153.18,0,0,0,27.34-22.63c16.48-17.28,28.06-38.39,34.92-60.46C416.64,294.63,418.19,271.51,417.26,249.11Zm-13,0a221.67,221.67,0,0,1-3.87,31.58,184.7,184.7,0,0,1-8.73,30c-7.61,19.22-18.45,37-32.87,50.93a137.32,137.32,0,0,1-51.4,30.88A192.71,192.71,0,0,1,247,402.08a186,186,0,0,1-60.53-9c-4.85-1.59-9.64-3.34-14.3-5.39A137.5,137.5,0,0,1,158.45,381a145.21,145.21,0,0,1-25.16-17.73A141,141,0,0,1,96.85,312c-8.42-19.84-11.9-41.8-12-63.56s3.37-43.76,11.82-63.61a141.18,141.18,0,0,1,36.62-51.3c31.46-27.49,72.47-39.67,114.15-41,20.9-.79,42,1.18,62.19,7.32a141.17,141.17,0,0,1,29.07,12.48,132.47,132.47,0,0,1,25.41,19.17c15.27,14.88,26.25,34,32.91,54.3S405.32,227.77,404.24,249.08Z"
      />
      <path
        fill={props.fill}
        d="M313,179.07l-2.56-4.13c-.94-1.33-1.87-2.69-2.86-4a75.21,75.21,0,0,0-6.41-7.34,72,72,0,0,0-15.85-12c-11.76-6.58-25.21-9.09-37.72-8.93s-25.84,3-37.3,9.68a63.32,63.32,0,0,0-15,12.35,65,65,0,0,0-5.79,7.5c-.87,1.32-1.68,2.71-2.51,4l-2.19,4.16c-5.35,11.32-7.3,23.1-8.27,34.41s-1,22.46-1,33.56.18,22.19,1.13,33.52,2.84,23,8.09,34.3c.67,1.39,1.41,2.76,2.13,4.15s1.63,2.69,2.48,4a65.94,65.94,0,0,0,5.77,7.43,60.6,60.6,0,0,0,15,12.09c11.41,6.43,24.66,8.75,36.88,7.94a72.48,72.48,0,0,0,35-11.28,70.74,70.74,0,0,0,13.79-11.79,86.34,86.34,0,0,0,10.51-14.16c5.92-10.08,9.71-21,12.38-32.06,1.32-5.54,2.47-11.12,3.4-16.75s1.66-11.28,2.17-17c1-11.34,1.56-23,0-34.78A91.7,91.7,0,0,0,313,179.07ZM310.83,281c-1.61,10.55-5,20.92-11,29.5A50.63,50.63,0,0,1,276,328.91c-9.34,3.4-19.2,3.82-29,3.1a76.41,76.41,0,0,1-27-6.75A68.65,68.65,0,0,1,208.18,318c-1.91-1.41-3.7-3-5.54-4.64s-3.45-3.37-5.08-5.24a77.72,77.72,0,0,1-15-27.37,105.57,105.57,0,0,1-4.66-32.36,107.52,107.52,0,0,1,4.9-32.3A74.52,74.52,0,0,1,198,189l2.52-2.65,2.66-2.42c1.89-1.55,3.73-3.1,5.68-4.42a68.42,68.42,0,0,1,64.91-5.86,47.52,47.52,0,0,1,11,7.24,58.78,58.78,0,0,1,4.88,4.76c.77.84,1.46,1.72,2.2,2.58l2,2.76c5.27,7.59,8.85,16.88,11.64,26.73,1.35,4.94,2.47,10,3.49,15.23s1.64,10.52,2.2,15.87C312.23,259.54,312.5,270.41,310.83,281Z"
      />
    </svg>
  )
}

export default O
