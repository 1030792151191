import React from 'react'
import {Link} from 'gatsby'
import ErrorBoundary from '../ErrorBoundary'
import ArrowCircleRight from '../icons/ArrowCircleRight'
import './Categories.scss'
import {GatsbyImage} from 'gatsby-plugin-image'

const Widget = ({title, image}) => {
    return (
        <ErrorBoundary>
            <Link to={`${title}`}>
                <div className="box widget-card">
                    <div className="card">
                        <div className="card-header">
                            <p className="is-capitalized card-header-title">{title}</p>
                        </div>
                        <div className="card-image">
                            <GatsbyImage image={image} alt={'image'}/>
                        </div>
                        <div className="card-footer">
                            <div className="card-footer-item">
                                <ArrowCircleRight color={'rgba(255,255,255,.5)'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </ErrorBoundary>
    )
}

export default Widget
