import React from 'react'
import Layout from '../components/layout'
import Parallax from '../components/Parallax/Parallax'
import Carousel from '../components/Carousel/Carousel'
import Categories from '../components/Categories/Categories'
import OpenTimes from '../components/OpenTimes/OpenTimes'
import IconBouquetCat from '../components/icons/IconBouquetCat'
import IconBirthdayCat from '../components/icons/IconBirthdayCat'
import IconDeliveryCat from '../components/icons/IconDeliveryCat'
import {Default, Mobile} from "../components/responsive";
import IconWeddingCat from "../components/icons/IconWeddingCat";

const IndexPage = (props) => {
    return (
        <Layout location={props.location} justifyContent={'center'}>
            <Default>
                <Parallax/>
            </Default>
            <Mobile>
                <div
                    style={{
                        marginTop: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        height: '5vh',
                    }}
                />
                <div
                    style={{
                        margin: '10px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <IconBirthdayCat height="120"/>
                </div>
                <OpenTimes/>
                <div
                    style={{
                        margin: '10px 0',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <IconBouquetCat height="120"/>
                </div>
                <Carousel/>
                <div
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <IconWeddingCat width="120"/>
                </div>
                <Categories/>
                <div
                    style={{
                        marginTop: '10px',
                        marginBottom: '-50px',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    <IconDeliveryCat width="100" height="100"/>
                </div>
            </Mobile>
        </Layout>
    )
}

export default IndexPage
