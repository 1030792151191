import React from 'react'

const OpenTimes = () => (
    <div className="columns is-mobile is-centered">
        <div className="card column is-two-thirds-tablet" style={{
            backgroundColor: `rgba(133, 112, 153, 0.6`

        }}>
            <header className="card-header">
                <p className="card-header-title">
                    Unsere Öffnungszeiten
                </p>
            </header>
            <div className="card-content" style={{maxWidth: `95%`, margin: `0 auto`}}>
                <p className="subtitle">Mo. bis Mi. haben wir zu</p>
                <p className="subtitle">Do. und Fr. haben wir von 10.00 bis 19.00 Uhr auf</p>
                <p className="subtitle">Sa. haben wir von 10.00 bis 17.00 Uhr auf </p>
                <p className="subtitle">So. haben wir von 11.00 bis 15.00 Uhr auf</p>
            </div>
        </div>
    </div>
)

export default OpenTimes
