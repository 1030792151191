import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import './Carousel.scss'

const Slide = ({ image }) => {
  return (
    <div className="slider-item">
      <div className="box">
        <GatsbyImage
          image={image}
          alt={'image'}
        />
      </div>
    </div>
  )
}

export default Slide
